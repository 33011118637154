import * as React from "react";
import Layout from "../components/layout";
import AccordionSection from "../containers/HomePage/AccordionSection/AccordionSection";
import Hero from "../containers/HomePage/Hero/Hero";
import ColorfulCTAComponent from "../containers/Common/ColorfulCTAComponent/ColorfulCTAComponent";
import HowCleverpayWorks from "../containers/HomePage/HowCleverpayWorks/HowCleverpayWorks";
import FutureCleverpayMerchants from "../containers/HomePage/FutureCleverpayMerchants/FutureCleverpayMerchants";
import BlogSection from "../containers/HomePage/BlogSection/BlogSection";
import Testimonials from "../containers/HomePage/Testimonials/Testimonials";
import { StickyContainer } from "react-sticky";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

// markup
const IndexPage = () => {
  const intl = useIntl();
  return (
    <StickyContainer>
      <Layout>
        <Hero />
        <AccordionSection />
        <HowCleverpayWorks />
        <FutureCleverpayMerchants />
        <Testimonials />
        <BlogSection />
        <ColorfulCTAComponent
          title={intl.formatMessage({ id: "Homepage.Signup.Title" })}
          subtitle={intl.formatMessage({ id: "Homepage.Signup.Subtitle" })}
          button={intl.formatMessage({ id: "Homepage.Signup.Button" })}
          link={"https://helloclever.typeform.com/enquiry"}
        />
      </Layout>
    </StickyContainer>
  );
};

export default IndexPage;
