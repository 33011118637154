import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./Testimonials.scss";
import $ from "jquery";
import Slider from "react-slick";
import { REVIEW_CONSTANTS } from "../../../Constants";
import { ReactComponent as ArrowLeft } from "../../../images/Homepage/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../../images/Homepage/arrow_right.svg";
var parse = require("html-react-parser");

export const getSubTitle = (data) => {
  const arr = data.split(".");
  return arr[0] || "";
};

const TestimonialItem = ({ item, index }) => {
  return (
    <div className="testimonials_cont_testimonialsCont_testimonial">
      <div className="testimonials_cont_testimonialsCont_testimonial_topCont">
        <img className="testimonials_cont_testimonialsCont_testimonial_topCont_image" src={item.avatar} alt={"avatar"} />
        <div className="testimonials_cont_testimonialsCont_testimonial_topCont_textCont">
          <p className="testimonials_cont_testimonialsCont_testimonial_topCont_textCont_name">{item.name}</p>
          <span className="testimonials_cont_testimonialsCont_testimonial_topCont_textCont_position">{item.position}</span>
        </div>
      </div>
      <p className="testimonials_cont_testimonialsCont_testimonial_text">{parse(item.review)}</p>
    </div>
  );
};

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderIndex: 0
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(this.currentSlideIndicator, {
      width: String(100 / REVIEW_CONSTANTS.length) + "%"
    });
    gsap.set([this.title, this.subtitle, this.label], {
      y: 50,
      opacity: 0
    });
    gsap.set(this.sliderCont, {
      x: "50%",
      opacity: 0
    });
    gsap.to(this.label, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.title, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.subtitle, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.sliderCont, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      x: 0,
      delay: 0.6,
      duration: 1,
      ease: "power3"
    });
  }

  previousSlide = () => {
    this.slider.slickPrev();
  };
  nextSlide = () => {
    this.canGoNext() && this.slider.slickNext();
  };

  changeSlide = (next) => {
    if (isMobile && !isTablet) {
      var totalWidth = Number(this.currentSlideIndicatorCont.clientWidth);
      var count = REVIEW_CONSTANTS.length;
      var moveBY = totalWidth * (next * (1 / count));
      gsap.to(this.currentSlideIndicator, { left: moveBY, duration: 0.5, ease: "power2" });
      gsap.set(this.currentSlideIndicatorCont, { background: "#f6f7fe" });
    }
  };

  canGoNext = () => {
    if (isBrowser) {
      var x = $(".slick-slide").last().offset().left;
      var width = $(".slick-slide").last().width();
      if (x + width < window.innerWidth) {
        if (window.innerWidth - (x + width) < 500) {
          return false;
        }
      }
    }
    return true;
  };

  render() {
    const { intl } = this.props;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      beforeChange: (current, next) => this.changeSlide(next)
    };
    return (
      <div className="testimonials">
        <div className="testimonials_cont">
          <div className="testimonials_cont_topRow">
            <div className="testimonials_cont_topRow_textCont">
              <span ref={(ref) => (this.label = ref)} className="testimonials_cont_topRow_textCont_label">
                {intl.formatMessage({ id: "Homepage.Testimonials.Label" })}
              </span>
              <h2 ref={(ref) => (this.title = ref)} className="testimonials_cont_topRow_textCont_title">
                {intl.formatMessage({ id: "Homepage.Testimonials.Title" })}
              </h2>
              <p ref={(ref) => (this.subtitle = ref)} className="testimonials_cont_topRow_textCont_description">
                {intl.formatMessage({ id: "Homepage.Testimonials.Subtitle" })}
              </p>
            </div>
            <div className="testimonials_cont_topRow_arrowCont">
              <div className="testimonials_cont_topRow_arrowCont_arrow" onClick={this.previousSlide}>
                <ArrowLeft />
              </div>
              <div className="testimonials_cont_topRow_arrowCont_arrow" onClick={this.nextSlide}>
                <ArrowRight />
              </div>
            </div>
          </div>
          <div ref={(ref) => (this.sliderCont = ref)} className="testimonials_cont_testimonialsCont">
            <Slider ref={(ref) => (this.slider = ref)} {...settings}>
              {REVIEW_CONSTANTS.map((item, index) => {
                return <TestimonialItem key={item.id} item={item} index={index}></TestimonialItem>;
              })}
            </Slider>
          </div>

          <div ref={(ref) => (this.currentSlideIndicatorCont = ref)} className="testimonials_cont_indicator">
            <div ref={(ref) => (this.currentSlideIndicator = ref)} className="testimonials_cont_indicator_box"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Testimonials);
