import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import moment from "moment";
import "./BlogSection.scss";
import { convertStringToURL } from "../../../utils/utils";

export const getSubTitle = (data) => {
  const arr = data.split(".");
  return arr[0] || "";
};

const BlogItem = ({ item, index }) => {
  const formatDate = (date) => {
    var dateObj = moment(date, "YYYY-MM-DD hh:mm:ss");
    return dateObj.isValid() ? dateObj.format("MMMM DD, YYYY") : date;
  };
  return (
    <Link to={"/blog/"+String(convertStringToURL(item))} target="_blank" rel="noreferrer" className="blogSection_cont_blogPostLink">
      <div id={"blogPost_" + index} className="blogSection_cont_blogsCont_blogPost">
        <div className="blogSection_cont_blogsCont_blogPost_imageCont">
          <img className="blogSection_cont_blogsCont_blogPost_imageCont_image" src={item.thumbnail} alt={"Hello Clever Blog"} />
        </div>
        <div className="blogSection_cont_blogsCont_blogPost_infoCont">
          <span className="blogSection_cont_blogsCont_blogPost_infoCont_author">{item.author || "Aircrex"}</span>
          <span className="blogSection_cont_blogsCont_blogPost_infoCont_date">
            {formatDate(String(item.pubDate)) || new Date().toDateString()}
          </span>
        </div>
        <p className="blogSection_cont_blogsCont_blogPost_text">{item.title}</p>
      </div>
    </Link>
  );
};

class BlogSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      blogs: []
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    this.callAPI();
  }

  animate = () => {
    console.log("calling animate");
    gsap.set(["#blogPost_0", "#blogPost_1", "#blogPost_2"], {
      y: 50,
      opacity: 0
    });
    gsap.to("#blogPost_0", {
      scrollTrigger: this.subtitle,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 1,
      ease: "power3"
    });
    gsap.to("#blogPost_1", {
      scrollTrigger: this.subtitle,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.7,
      duration: 1,
      ease: "power3"
    });
    gsap.to("#blogPost_2", {
      scrollTrigger: this.subtitle,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.9,
      duration: 1,
      ease: "power3"
    });
  };

  callAPI = async () => {
    try {
      const res = await fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/@hello-clever/feed");
      const data = (await res.json()) || {};
      const blogResponse = data.items || [];

      this.setState({ items: blogResponse.slice(0, 3) }, function () {
        setTimeout(() => {
          this.animate();
        }, 300);
      });
      this.setState({ blogs: blogResponse });
    } catch (error) {
      this.setState({ items: [] });
      this.setState({ blogs: [] });
    }
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="blogSection">
        <div className="blogSection_cont">
          <div className="blogSection_cont_textCont">
            <span className="blogSection_cont_textCont_label">{intl.formatMessage({ id: "Homepage.BlogSection.Label" })}</span>
            <h2 ref={(ref) => (this.title = ref)} className="blogSection_cont_textCont_title">
              {intl.formatMessage({ id: "Homepage.BlogSection.Title" })}
            </h2>
            <p ref={(ref) => (this.subtitle = ref)} className="blogSection_cont_textCont_description">
              {intl.formatMessage({ id: "Homepage.BlogSection.Subtitle" })}
            </p>
          </div>
          <div className="blogSection_cont_blogsCont">
            {this.state.items.map((item, index) => {
              return <BlogItem key={item.id} item={item} index={index}></BlogItem>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(BlogSection);
