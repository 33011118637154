import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import logo from "../../../images/header/clever-icon.png";
import ScrollTrigger from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import lottieData from "../../../lotties/Homepage/how_it_work_animation.json";
import AppDownloadButton from "../../../components/AppDownloadButton/AppDownloadButton";
import "./HowCleverpayWorks.scss";
import GetCleverComponent from "../../../components/GetCleverComponent/GetCleverComponent";
import payID from "../../../images/Homepage/payID.svg";
import payTO from "../../../images/Homepage/payTo.svg";

var lottie1 = null;

class HowCleverpayWorks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 1
    };
  }

  componentDidMount() {
    this.setupLotties();
    gsap.registerPlugin(ScrollTrigger);
    gsap.set([this.lottieCont, this.title, this.subtitle, this.item1, this.item2, this.item3, this.item4], {
      y: 50,
      opacity: 0
    });
    gsap.set([this.lottieCont, this.lottie], {
      opacity: 0,
      transform: "scale(0.5)"
    });
    gsap.to(this.lottieCont, {
      scrollTrigger: this.lottieCont,
      start: "top 70%",
      opacity: 1,
      transform: "scale(1)",
      y: 0,
      delay: 0.2,
      duration: 0.5,
      onStart: function () {
        lottie1?.play();
      },
      ease: "power3"
    });
    //playing lottie
    gsap.to(this.lottie, {
      scrollTrigger: this.lottie,
      start: "top 70%",
      opacity: 1,
      transform: "scale(1)",
      y: 0,
      delay: 0.4,
      duration: 0.3,
      ease: "power3"
    });
    gsap.to(this.title, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.subtitle, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.item1, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.item2, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.4,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.item3, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.item4, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.6,
      duration: 1,
      ease: "power3"
    });
  }

  setupLotties = () => {
    lottie1 = lottie.loadAnimation({
      container: this.lottie,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: lottieData
    });
  };

  changeTab = (tabNo) => {
    if (tabNo == 1) {
      gsap.to(this.tab2, {
        opacity: 0,
        delay: 0,
        duration: 0.2,
        ease: "power3",
        onComplete: function () {
          this.tab2.style.display = "none";
          gsap.set(this.tab1, {
            opacity: 0
          });
          this.tab1.style.display = "flex";
          gsap.to(this.tab1, {
            opacity: 1,
            delay: 0,
            duration: 0.5,
            ease: "power3"
          });
        }.bind(this)
      });
    }
    if (tabNo == 2) {
      gsap.to(this.tab1, {
        opacity: 0,
        delay: 0,
        duration: 0.2,
        ease: "power3",
        onComplete: function () {
          this.tab1.style.display = "none";
          gsap.set(this.tab2, {
            opacity: 0
          });
          this.tab2.style.display = "flex";
          gsap.to(this.tab2, {
            opacity: 1,
            delay: 0,
            duration: 0.5,
            ease: "power3"
          });
        }.bind(this)
      });
    }
    this.setState({ tab: tabNo })
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="howCleverpayWorks">
        <div className="howCleverpayWorks_cont">
          <div ref={(ref) => (this.lottieCont = ref)} className="howCleverpayWorks_cont_leftCont">
            <div ref={(ref) => (this.lottie = ref)} className="howCleverpayWorks_cont_leftCont_lottie"></div>
          </div>
          <div className="howCleverpayWorks_cont_rightCont">
            <h2 ref={(ref) => (this.title = ref)}>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Title" })}</h2>
            <p ref={(ref) => (this.subtitle = ref)}>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Subtitle" })}</p>
            <div className="howCleverpayWorks_cont_rightCont_tabSelector">
              <div className="howCleverpayWorks_cont_rightCont_tabSelector_tab" onClick={() => this.changeTab(1)} style={{ opacity: this.state.tab == 1 ? 1 : 0.3, borderBottom: this.state.tab == 1 ? "4px solid #3E64FF" : "unset" }}><img src={payID} /></div>
              <div className="howCleverpayWorks_cont_rightCont_tabSelector_tab" onClick={() => this.changeTab(2)} style={{ opacity: this.state.tab == 2 ? 1 : 0.3, borderBottom: this.state.tab == 2 ? "4px solid #3E64FF" : "unset" }}><img src={payTO} /></div>
            </div>
            <div ref={(ref) => (this.tab1 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont">
              <div ref={(ref) => (this.item1 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont_item">
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_circle">
                  <span>01.</span>
                </div>
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_textCont">
                  <h3>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item1.Title" })}</h3>
                  <p>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item1.Subtitle" })}</p>
                </div>
              </div>
              <div ref={(ref) => (this.item2 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont_item">
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_circle">
                  <span>02.</span>
                </div>
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_textCont">
                  <h3>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item2.Title" })}</h3>
                  <p>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item2.Subtitle" })}</p>
                </div>
              </div>
              <div ref={(ref) => (this.item3 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont_item">
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_circle">
                  <span>03.</span>
                </div>
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_textCont">
                  <h3>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item3.Title" })}</h3>
                  <p>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item3.Subtitle" })}</p>
                </div>
              </div>
              <div ref={(ref) => (this.item4 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont_item">
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_circle">
                  <span>04.</span>
                </div>
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_textCont">
                  <h3>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item4.Title" })}</h3>
                  <p>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item4.Subtitle" })}</p>
                </div>
              </div>
            </div>
            <div ref={(ref) => (this.tab2 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont">
              <div ref={(ref) => (this.item1 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont_item">
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_circle">
                  <span>01.</span>
                </div>
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_textCont">
                  <h3>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item5.Title" })}</h3>
                  <p>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item5.Subtitle" })}</p>
                </div>
              </div>
              <div ref={(ref) => (this.item2 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont_item">
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_circle">
                  <span>02.</span>
                </div>
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_textCont">
                  <h3>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item6.Title" })}</h3>
                  <p>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item6.Subtitle" })}</p>
                </div>
              </div>
              <div ref={(ref) => (this.item3 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont_item">
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_circle">
                  <span>03.</span>
                </div>
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_textCont">
                  <h3>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item7.Title" })}</h3>
                  <p>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item7.Subtitle" })}</p>
                </div>
              </div>
              <div ref={(ref) => (this.item4 = ref)} className="howCleverpayWorks_cont_rightCont_itemsCont_item">
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_circle">
                  <span>04.</span>
                </div>
                <div className="howCleverpayWorks_cont_rightCont_itemsCont_item_textCont">
                  <h3>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item8.Title" })}</h3>
                  <p>{intl.formatMessage({ id: "Homepage.HowCleverpayWorks.Items.Item8.Subtitle" })}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GetCleverComponent />
      </div>
    );
  }
}
export default injectIntl(HowCleverpayWorks);
