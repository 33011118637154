import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import downArrow from "../../../images/Homepage/down-arrow.svg";
import lottie from "lottie-web";
import AStart from "../../../lotties/Homepage/accordeon-A-START.json";
import Dmobile from "../../../lotties/Homepage/accordeon-C-mobile.json";
import D from "../../../lotties/Homepage/accordeon-C.json";
import C from "../../../lotties/Homepage/accordeon-D.json";
import Bimage from "../../../images/Homepage/accordeon-B.png";
import Cimage from "../../../images/Homepage/accordeon-C.png";
import Cimage_mobile from "../../../images/Homepage/accordeon-C-mob.png";
import Bimage_mobile from "../../../images/Homepage/iphone_mobile.png";

import "./AccordionSection.scss";

class AccordionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: "A"
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set([this.title1, this.descr1, this.button1], {
      y: 50,
      opacity: 0
    });
    if (this.state.expand == "A") {
      gsap.to(this.title1, {
        scrollTrigger: this.title1,
        start: "top 70%",
        opacity: 1,
        y: 0,
        delay: 0.1,
        duration: 1,
        ease: "power3",
        onStart: function () {
          this.setLottie(AStart, this.lottie1);
        }.bind(this)
      });
      gsap.to(this.descr1, {
        scrollTrigger: this.title1,
        start: "top 70%",
        opacity: 1,
        y: 0,
        delay: 0.3,
        duration: 1,
        ease: "power3"
      });
      gsap.to(this.button1, {
        scrollTrigger: this.title1,
        start: "top 70%",
        opacity: 1,
        y: 0,
        delay: 0.5,
        duration: 1,
        ease: "power3"
      });
    }
  }
  componentWillUnmount() {
    this.lottieObj?.destroy();
  }

  setLottie = (lottieData, container, loop) => {
    if (lottieData) {
      if (this.lottieObj) {
        this.lottieObj.destroy();
      }
      setTimeout(() => {
        this.lottieObj = lottie.loadAnimation({
          container: container,
          renderer: "svg",
          loop: loop ? loop : false,
          autoplay: true,
          animationData: lottieData
        });
      }, 200);
    }
  };

  animateShowOfBImage = () => {
    gsap.set(this.bimage, {
      opacity: 0,
      y: -100
    });
    gsap.to(this.bimage, {
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1.0,
      ease: "power3"
    });
  };

  animateShowOfCImage = () => {
    gsap.set(this.cimage, {
      opacity: 0,
      y: 100
    });
    gsap.to(this.cimage, {
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1.0,
      ease: "power3"
    });
  };

  playTextAnimations = (title, descr, button) => {
    gsap.set([title, descr, button], {
      y: 50,
      opacity: 0
    });
    gsap.to(title, {
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(descr, {
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });
    gsap.to(button, {
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 1,
      ease: "power3"
    });
  };

  handleChange = (nextState) => {
    var previousState = this.state.expand;
    var nextLottie = null;
    var nextContainer = null;
    var loop = false;
    this.setState({ expand: nextState });

    if (nextState == "A") {
      nextLottie = AStart;
      nextContainer = this.lottie1;
      this.playTextAnimations(this.title1, this.descr1, this.button1);
    } else if (nextState == "B") {
      nextLottie = null;
      nextContainer = null;
      this.playTextAnimations(this.title2, this.descr2, this.button2);
      this.animateShowOfBImage();
    } else if (nextState == "C") {
      nextLottie = C;
      nextContainer = this.lottie3;
      loop = true;
      this.playTextAnimations(this.title3, this.descr3, null);
      this.animateShowOfCImage();
    } else if (nextState == "D") {
      nextLottie = isMobile && !isTablet ? Dmobile : D;
      nextContainer = this.lottie4;
      loop = true;
      this.playTextAnimations(this.title4, this.descr4, null);
    }

    this.setLottie(nextLottie, nextContainer, loop);
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="accordionSection">
        <div className="accordionSection_cont">
          <Accordion
            className="accordion accordion-1"
            TransitionProps={{ timeout: 500 }}
            ref={(ref) => (this.accordionA = ref)}
            expanded={this.state.expand === "A"}
            disableGutters={true}
            square={true}
            onChange={() => this.handleChange("A")}>
            <AccordionSummary
              expandIcon={isMobile && <img src={downArrow} />}
              className="accordion_header accordion_header-1"
              aria-controls="panel1a-content"
              id="panel1a-header">
              <div className="accordion_header_textCont">
                <span className="accordion_header_textCont_number">01.</span>
                <h4 className="accordion_header_textCont_text">
                  {this.state.expand != "A" && intl.formatMessage({ id: "Homepage.AccordionSection.FirstCard.Title" })}
                </h4>
              </div>
            </AccordionSummary>
            <AccordionDetails className="accordion_detail accordion_detail-1">
              <div className="accordion_detail_leftCont">
                <h2 ref={(ref) => (this.title1 = ref)} className="accordion_detail_leftCont_title">
                  {intl.formatMessage({ id: "Homepage.AccordionSection.FirstCard.Title-part-1" })}
                  <br></br>
                  <strong>{intl.formatMessage({ id: "Homepage.AccordionSection.FirstCard.Title-part-2" })}</strong>
                  <br></br>
                  <strong>{intl.formatMessage({ id: "Homepage.AccordionSection.FirstCard.Title-part-3" })}</strong>
                </h2>
                <p ref={(ref) => (this.descr1 = ref)} className="accordion_detail_leftCont_description">
                  {intl.formatMessage({ id: "Homepage.AccordionSection.FirstCard.Subtitle" })}
                </p>
                <OutboundLink href="https://helloclever.typeform.com/enquiry" target="_blank">
                  <div ref={(ref) => (this.button1 = ref)} className="button button-transparent accordion_detail_leftCont_button">
                    <p>{intl.formatMessage({ id: "Homepage.AccordionSection.FirstCard.Button" })}</p>
                  </div>
                </OutboundLink>
              </div>
              <div className="accordion_detail_rightCont">
                <div className="accordion_detail_rightCont_lottieCont1" ref={(ref) => (this.lottie1 = ref)}></div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="accordion accordion-2"
            expanded={this.state.expand === "B"}
            ref={(ref) => (this.accordionB = ref)}
            disableGutters={true}
            square={true}
            onChange={() => this.handleChange("B")}>
            <AccordionSummary
              expandIcon={isMobile && <img src={downArrow} />}
              className="accordion_header accordion_header-2"
              aria-controls="panel1a-content"
              id="panel1a-header">
              <div className="accordion_header_textCont">
                <span className="accordion_header_textCont_number">02.</span>
                <h4 className="accordion_header_textCont_text">
                  {this.state.expand != "B" && intl.formatMessage({ id: "Homepage.AccordionSection.SecondCard.Title" })}
                </h4>
              </div>
            </AccordionSummary>
            <AccordionDetails className="accordion_detail accordion_detail-2">
              <div className="accordion_detail_leftCont">
                <h2 ref={(ref) => (this.title2 = ref)} className="accordion_detail_leftCont_title">
                  {intl.formatMessage({ id: "Homepage.AccordionSection.SecondCard.Title-part-1" })} <br></br>
                  {intl.formatMessage({ id: "Homepage.AccordionSection.SecondCard.Title-part-2" })} <br></br>
                  <strong>{intl.formatMessage({ id: "Homepage.AccordionSection.SecondCard.Title-part-3" })}</strong>
                </h2>
                <p ref={(ref) => (this.descr2 = ref)} className="accordion_detail_leftCont_description">
                  {intl.formatMessage({ id: "Homepage.AccordionSection.SecondCard.Subtitle" })}
                </p>
                <OutboundLink href="https://merchant.helloclever.co/enter-email" target="_blank">
                  <div ref={(ref) => (this.button2 = ref)} className="button button-transparent accordion_detail_leftCont_button">
                    <p>{intl.formatMessage({ id: "Homepage.AccordionSection.SecondCard.Button" })}</p>
                  </div>
                </OutboundLink>
              </div>
              <div className="accordion_detail_rightCont">
                {(isBrowser || isTablet) && (
                  <img
                    ref={(ref) => (this.bimage = ref)}
                    className="accordion_detail_rightCont_bimage"
                    src={Bimage}
                    alt={"aircrex clever mockup"}
                  />
                )}
                {isMobile && !isTablet && (
                  <div className="accordion_detail_rightCont_bimageMobile">
                    <div className="accordion_detail_rightCont_bimageMobile_overlay"></div>
                    <img
                      alt={"aircrex clever mockup"}
                      className="accordion_detail_rightCont_bimageMobile_image"
                      src={Bimage_mobile}
                    />
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="accordion accordion-3"
            expanded={this.state.expand === "C"}
            ref={(ref) => (this.accordionC = ref)}
            disableGutters={true}
            square={true}
            onChange={() => this.handleChange("C")}>
            <AccordionSummary
              expandIcon={isMobile && <img src={downArrow} />}
              className="accordion_header accordion_header-3"
              aria-controls="panel1a-content"
              id="panel1a-header">
              <div className="accordion_header_textCont">
                <span className="accordion_header_textCont_number">03.</span>
                <h4 className="accordion_header_textCont_text">
                  {this.state.expand != "C" && intl.formatMessage({ id: "Homepage.AccordionSection.ThirdCard.Title" })}
                </h4>
              </div>
            </AccordionSummary>
            <AccordionDetails className="accordion_detail accordion_detail-3">
              <div className="accordion_detail_leftCont">
                <h2 ref={(ref) => (this.title3 = ref)} className="accordion_detail_leftCont_title">
                  <strong>{intl.formatMessage({ id: "Homepage.AccordionSection.ThirdCard.Title-part-1" })}</strong> <br></br>
                  <strong>{intl.formatMessage({ id: "Homepage.AccordionSection.ThirdCard.Title-part-2" })}</strong>{" "}
                  {intl.formatMessage({ id: "Homepage.AccordionSection.ThirdCard.Title-part-3" })}
                  <br></br>{" "}
                  {intl.formatMessage({ id: "Homepage.AccordionSection.ThirdCard.Title-part-4" })}
                </h2>
                <p ref={(ref) => (this.descr3 = ref)} className="accordion_detail_leftCont_description">
                  {intl.formatMessage({ id: "Homepage.AccordionSection.ThirdCard.Subtitle" })}
                </p>
              </div>
              <div className="accordion_detail_rightCont">
                <div className="accordion_detail_rightCont_lottieCont3" ref={(ref) => (this.lottie3 = ref)}></div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="accordion accordion-4"
            expanded={this.state.expand === "D"}
            ref={(ref) => (this.accordionD = ref)}
            disableGutters={true}
            square={true}
            onChange={() => this.handleChange("D")}>
            <AccordionSummary
              expandIcon={isMobile && <img src={downArrow} />}
              className="accordion_header accordion_header-4"
              aria-controls="panel1a-content"
              id="panel1a-header">
              <div className="accordion_header_textCont">
                <span className="accordion_header_textCont_number">04.</span>
                <h4 className="accordion_header_textCont_text">
                  {this.state.expand != "D" && intl.formatMessage({ id: "Homepage.AccordionSection.FourthCard.Title" })}
                </h4>
              </div>
            </AccordionSummary>
            <AccordionDetails className="accordion_detail accordion_detail-4">
              <div className="accordion_detail_leftCont">
                <h2 className="accordion_detail_leftCont_title" ref={(ref) => (this.title4 = ref)}>
                  {intl.formatMessage({ id: "Homepage.AccordionSection.FourthCard.Title-part-1" })}
                  <br></br>
                  <strong>{intl.formatMessage({ id: "Homepage.AccordionSection.FourthCard.Title-part-2" })}</strong>
                  <br></br>
                  <strong>{intl.formatMessage({ id: "Homepage.AccordionSection.FourthCard.Title-part-3" })}</strong>
                </h2>
                <p ref={(ref) => (this.descr4 = ref)} className="accordion_detail_leftCont_description">
                  {intl.formatMessage({ id: "Homepage.AccordionSection.FourthCard.Subtitle" })}
                </p>
              </div>

              <div className="accordion_detail_rightCont">
                <div className="accordion_detail_rightCont_lottieCont4" ref={(ref) => (this.lottie4 = ref)}></div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    );
  }
}
export default injectIntl(AccordionSection);
