import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import tabletImg from "../../../images/Homepage/future_cleverpay_merchants-tablet.png";

import "./FutureCleverpayMerchants.scss";

var lottie1 = null;

class FutureCleverpayMerchants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set([this.title, this.subtitle, this.button, this.tabletImg], {
      y: 50,
      opacity: 0
    });
    gsap.set([this.tabletImg], {
      transform: "scale(0.5)"
    });
    //playing lottie
    gsap.to(this.tabletImg, {
      scrollTrigger: this.tabletImg,
      start: "top 70%",
      opacity: 1,
      transform: "scale(1)",
      y: 0,
      delay: 0.4,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.title, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.subtitle, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.button, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="futureCleverpayMerchants">
        <div className="futureCleverpayMerchants_cont">
          <div className="futureCleverpayMerchants_cont_leftCont">
            <h2 ref={(ref) => (this.title = ref)} className="futureCleverpayMerchants_cont_leftCont_title">
              {intl.formatMessage({ id: "Homepage.FutureCleverpayMerchants.Title-part-1" })}
              <br></br>
              <strong>{intl.formatMessage({ id: "Homepage.FutureCleverpayMerchants.Title-part-2" })}</strong>
              <br></br>
              <strong>{intl.formatMessage({ id: "Homepage.FutureCleverpayMerchants.Title-part-3" })}</strong>
            </h2>
            <p ref={(ref) => (this.subtitle = ref)} className="futureCleverpayMerchants_cont_leftCont_description">
              {intl.formatMessage({ id: "Homepage.FutureCleverpayMerchants.Subtitle" })}
            </p>
            <OutboundLink href="https://helloclever.typeform.com/enquiry" target="_blank">
              <div
                ref={(ref) => (this.button = ref)}
                className="button button-transparent futureCleverpayMerchants_cont_leftCont_button">
                <p>{intl.formatMessage({ id: "Homepage.FutureCleverpayMerchants.Button" })}</p>
              </div>
            </OutboundLink>
          </div>
          <div ref={(ref) => (this.tabletImgCont = ref)} className="futureCleverpayMerchants_cont_rightCont">
            <img ref={(ref) => (this.tabletImg = ref)} src={tabletImg} className="futureCleverpayMerchants_cont_rightCont_img" />
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(FutureCleverpayMerchants);
