export const REVIEW_CONSTANTS = [
  {
    id: 1,
    showTime: 500,
    avatar: "/testminonial_avatar1.webp",
    name: "@budget_save_spend",
    position: "Customer",
    review:
      "I was always skeptical about cashback but decided to try Hello Clever because it was simple and easy to understand. All it takes is to shop with my favourite stores through the app."
  },
  {
    id: 2,
    showTime: 600,
    avatar: "/testminonial_avatar2.webp",
    name: "@Brokefromboba",
    position: "Customer",
    review:
      "Couldn’t be happier, love the convenience not having to look for cards when purchasing online. Love the fact that bigger purchasers can be made easily and securely via PayID."
  },
  {
    id: 3,
    showTime: 700,
    avatar: "/testminonial_avatar3.jpeg",
    name: "@genz.money",
    position: "Merchant",
    review:
      "Love it that Hello Clever allows my WooCommerce website to receive payments in real time 24/7. Started to see a significant increase in sales volume after the first week of integration."
  }
];
